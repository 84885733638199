@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 900px) {
  .pcnone {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #333333;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.6rem;
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
}

.inner2 {
  max-width: 1642px;
  margin: auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  padding-bottom: 70px;
}

#contents {
  width: 100%;
  max-width: 1000px;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 0;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

#g-nav.panelactive {
  background-color: #fff;
}

#g-nav.panelactive .sp_contact {
  margin-top: 30px;
}

.navR {
  margin-left: auto;
}

#g-nav {
  position: relative;
}

#g-nav .mainNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 50px;
  }
}

@media screen and (max-width: 834px) {
  #g-nav .mainNav {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 50px;
  }
}

#g-nav .mainNav li {
  position: relative;
}

#g-nav .mainNav li:last-of-type a {
  border-right: 1px dotted #777777;
}

#g-nav .mainNav li a {
  text-align: center;
  padding: 10px;
  display: block;
  position: relative;
  color: #333333;
  border-left: 1px dotted #777777;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  #g-nav .mainNav li a {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
    border-bottom: 1px solid #ccc;
    text-align: left;
    position: relative;
    font-weight: bold;
    border-left: none;
  }
  #g-nav .mainNav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -2000%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: all 0.6s;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
    margin-top: 0;
  }
}

#toggle .is-open {
  display: block;
}

.dropdown {
  width: 250px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: none;
}

@media only screen and (max-width: 900px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

.dropdown li:last-of-type a {
  border-bottom: none;
}

.dropdown li a {
  background-color: #ffffff;
  display: block;
  color: #333333 !important;
  border-bottom: 1px dotted #777777;
  padding: 15px !important;
  border-left: none !important;
}

@media only screen and (max-width: 900px) {
  .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #333333 !important;
  }
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .toggle {
    z-index: 100;
  }
}

/*ハンバーガーメニュー*/
.openBtn {
  display: none;
}

@media only screen and (max-width: 900px) {
  .openBtn {
    display: block;
  }
}

/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.openBtn {
  position: fixed;
  /*ボタン内側の基点となるためrelativeを指定*/
  top: 0;
  right: 0;
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-color: #199640;
  border-radius: 0 0 0 50px;
  box-shadow: -29px 28px 41px -49px #dcdada;
  z-index: 10000;
  padding-bottom: 20px;
}

.btnArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ボタン内側*/
.openBtn span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  height: 2px;
  border-radius: 5px;
  background: #fff;
  width: 35%;
}

.openBtn span:nth-of-type(2) {
  margin: 5px 0;
}

/*activeクラスが付与されると線が回転して×になり、Menu⇒Closeに変更*/
.openBtn.active span:nth-of-type(1) {
  top: 14px;
  left: 18px;
  transform: translateY(5px) rotate(-45deg);
  width: 40%;
}

.openBtn.active span:nth-of-type(2) {
  opacity: 0;
}

.openBtn.active span:nth-of-type(3) {
  top: 26px;
  left: 18px;
  transform: translateY(-8px) rotate(45deg);
  width: 40%;
}

.header {
  padding: 30px 20px 30px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header {
  z-index: 9999;
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: fit-content;
  left: 0;
  right: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.header--unpinned {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

.header--pinned {
  background-color: #fff;
}

@media only screen and (max-width: 900px) {
  .header--pinned {
    background-color: inherit;
  }
}

.header--pinned #g-nav {
  display: block;
}

.h_contact, .fix {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.h_contact a, .fix a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  line-height: 1em;
  min-width: 230px;
  font-size: 1.8rem;
  padding: 24px;
  color: #fff;
}

.h_contact .h_tel, .fix .h_tel {
  margin-right: 15px;
  background-color: #777777;
}

@media only screen and (max-width: 900px) {
  .h_contact .h_tel, .fix .h_tel {
    margin-right: 0;
  }
}

.h_contact .h_mail, .fix .h_mail {
  background-color: #199640;
}

.h_contact img, .h_contact i, .fix img, .fix i {
  margin-right: 10px;
}

@media only screen and (max-width: 900px) {
  .h_contact {
    display: none;
  }
}

.fix {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 9999;
}

@media only screen and (max-width: 900px) {
  .fix {
    display: flex;
    align-items: center;
  }
}

.mainWrap {
  position: relative;
}

.mainWrap .mainText {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.subArea {
  border-top: 1px solid #777777;
  border-bottom: 1px solid #777777;
  background-position-y: center;
  background-size: cover;
}

.subArea h1 {
  max-width: 1200px;
  margin: auto;
  padding: 5rem 0;
  font-weight: bold;
  font-size: clamp(36px, 3vw, 36px);
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.25em;
  color: #fff;
  text-shadow: 2px 2px 5px #000;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .subArea h1 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .subArea h1 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.blog_subArea {
  border-top: 1px solid #777777;
  border-bottom: 1px solid #777777;
  background-position: center;
  background-size: cover;
  background-image: url(../images/common/main01.jpg);
}

.blog_subArea .blog_title {
  max-width: 1200px;
  margin: auto;
  padding: 5rem 0;
  font-weight: bold;
  font-size: clamp(36px, 3vw, 36px);
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.25em;
  color: #fff;
  text-shadow: 2px 2px 5px #000;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .blog_subArea .blog_title {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .blog_subArea .blog_title {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #333333;
}

@media screen and (max-width: 640px) {
  .pankuzu {
    margin-top: 0;
  }
}

.pankuzu ul div::before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 0 10px;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

footer#global_footer {
  background-color: #E0E0E0;
  padding: 40px 0 0;
}

@media only screen and (max-width: 900px) {
  footer#global_footer {
    text-align: center;
  }
}

footer#global_footer .footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: auto;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 640px) {
  footer#global_footer .footer {
    display: block;
  }
}

footer#global_footer .ft_logo {
  margin-bottom: 10px;
}

@media screen and (max-width: 640px) {
  footer#global_footer .ft_logo {
    margin-bottom: 30px;
  }
}

footer#global_footer .ft_L {
  text-align: left;
}

@media screen and (max-width: 640px) {
  footer#global_footer .ft_L {
    text-align: center;
  }
}

footer#global_footer .ft_L .ft_tel a {
  color: #333333;
  font-size: 2.4rem;
  font-weight: bold;
}

footer#global_footer .ft_L .ft_fax {
  font-size: 2.4rem;
  font-weight: bold;
}

footer#global_footer .ftNav {
  display: flex;
  flex-flow: column wrap;
  height: 160px;
  max-width: 550px;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .ftNav {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 640px) {
  footer#global_footer .ftNav {
    margin-top: 15px;
    height: auto;
    max-width: 100%;
  }
}

footer#global_footer .ftNav li {
  text-align: left;
}

footer#global_footer .ftNav li a {
  text-align: left;
  padding: 10px;
  display: block;
  color: #333333;
  line-height: 1.25em;
}

@media screen and (max-width: 640px) {
  footer#global_footer .ftNav li a {
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #333333;
    padding: 15px;
    border-radius: 5px;
    margin: auto auto 5px auto;
    max-width: 420px;
  }
}

footer#global_footer .copy {
  margin-top: auto;
}

@media only screen and (max-width: 900px) {
  footer#global_footer .copy {
    margin-top: 30px;
  }
}

footer#global_footer #copy {
  background-color: #777777;
  display: block;
  width: 100%;
  color: #fff !important;
  font-size: 14px;
  padding: 20px;
  margin-top: 40px;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff !important;
}

footer#global_footer #pagetop {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 10;
}

@media only screen and (max-width: 900px) {
  footer#global_footer #pagetop {
    bottom: 90px;
  }
}

.title_01 h2 {
  font-size: clamp(32px, 3vw, 32px);
  line-height: 1.5em;
  position: relative;
  font-weight: bold;
}

.title_01 h2::before {
  content: "";
  width: 300px;
  height: 1px;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #199640;
}

.title_02 h2 {
  font-size: clamp(32px, 3vw, 32px);
  line-height: 1.5em;
  position: relative;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 5px #000;
}

.title_02 h2 .en {
  display: block;
  font-size: 2rem;
}

.title_02 h2::before {
  content: "";
  width: 300px;
  height: 1px;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
}

.title_03 h2 {
  font-size: clamp(26px, 2vw, 26px);
  border-bottom: 1px solid #199640;
  padding-bottom: 15px;
  font-weight: bold;
}

.title_04 h2 {
  font-size: clamp(32px, 3vw, 32px);
  line-height: 1.5em;
  position: relative;
  font-weight: bold;
}

.title_04 h2 .en {
  display: block;
  font-size: 2rem;
  color: #ef8100;
}

.title_04 h2::before {
  content: "";
  width: 300px;
  height: 1px;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #199640;
}

.news {
  max-height: 480px;
  overflow-y: auto;
}

.news dt,
.news dd {
  border: none !important;
}

@media screen and (max-width: 640px) {
  .news dt,
  .news dd {
    width: 100% !important;
  }
}

.news dt {
  color: #ef8100;
  font-weight: bold;
}

.news dl {
  border-bottom: 1px dotted #199640;
  padding: 15px 0;
  align-items: flex-start;
  justify-content: space-between;
}

.news dl a {
  text-decoration: underline;
}

@media screen and (max-width: 640px) {
  .news dl {
    display: block !important;
  }
}

.text div {
  line-height: 2em;
}

.fb {
  /* PCでは横幅500pxで表示 */
  /* 896px以下は横幅100%、中央寄せ*/
}

.fb .fb-wrap {
  /* 以下2行は不要かも */
  max-width: 500px;
  width: 500px;
}

@media screen and (max-width: 896px) {
  .fb .fb-wrap {
    width: 100%;
    text-align: center !important;
    margin: 0 auto !important;
  }
  .fb .fb-page {
    text-align: center !important;
    margin: 0 auto !important;
  }
}

.cont_01 {
  background-size: cover;
  padding: 100px 0;
}

.cont_01 .inner {
  position: relative;
}

@media only screen and (min-width: 835px) and (max-width: 1100px) {
  .cont_01 .inner .contWrap {
    background-color: rgba(255, 255, 255, 0.8);
    width: fit-content;
    margin: auto;
    padding: 15px;
  }
}

@media screen and (max-width: 834px) {
  .cont_01 .inner .contWrap {
    margin-bottom: 50px;
  }
}

.cont_01 .inner .humanL {
  position: absolute;
  left: 0;
  bottom: -60px;
  z-index: -1;
}

@media screen and (max-width: 834px) {
  .cont_01 .inner .humanL {
    position: inherit;
  }
}

.cont_01 .inner .humanR {
  position: absolute;
  right: 0;
  bottom: -60px;
  z-index: -1;
}

@media screen and (max-width: 834px) {
  .cont_01 .inner .humanR {
    position: inherit;
  }
}

.cont_01 .inner .imgBox {
  flex-wrap: inherit !important;
}

@media screen and (max-width: 834px) {
  .cont_01 .inner .imgBox {
    justify-content: space-between;
  }
}

.cont_02 {
  background-size: cover;
  padding: 100px 0;
}

.cont_03 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .cont_03 {
    display: block;
  }
}

.cont_03 .flxL {
  width: 48%;
}

.cont_03 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_03 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_03 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_03 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_03 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_03 .flxR .title_03 h2 {
  border-color: #2d3ba0;
}

.cont_04 {
  background-size: cover;
  padding: 100px 0;
}

.cont_04 .in {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 50px;
  max-width: 760px;
  margin: auto;
}

@media screen and (max-width: 834px) {
  .cont_04 .in {
    padding: 50px 10px;
  }
}

.flex_01 {
  justify-content: space-between;
}

.flex_01 .box {
  width: calc((100% - 40px) / 2);
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .flex_01 .box {
    width: 100%;
  }
}

.flex_01 .box h3 {
  text-align: center;
  font-weight: bold;
  font-size: 1.9rem;
  margin: 20px 0;
}

.flex_01 .box article > div {
  line-height: 2em;
}

.flex_02 {
  justify-content: space-between;
}

.flex_02 .box {
  width: calc((100% - 40px) / 2);
}

@media screen and (max-width: 640px) {
  .flex_02 .box {
    width: 100%;
  }
  .flex_02 .box:first-of-type {
    margin-bottom: 20px;
  }
}

.flex_02 .box a {
  color: #fff;
  font-weight: bold;
  display: block;
  padding: 20px;
  text-align: center;
  max-width: 230px;
  margin: auto;
}

.flex_02 .box:first-of-type a {
  background-color: #199640;
}

.flex_02 .box:last-of-type a {
  background-color: #005C9D;
}

.flex_03 {
  justify-content: space-between;
}

.flex_03 .box {
  width: calc((100% - 60px) / 3);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  color: #fff;
}

@media screen and (max-width: 640px) {
  .flex_03 .box {
    width: 100%;
    margin-bottom: 15px;
  }
  .flex_03 .box:last-of-type {
    margin-bottom: 0;
  }
}

.flex_03 .box article > div {
  padding: 30px;
}

.flex_03 .box img {
  width: 100% !important;
}

.flex_03 .box a {
  color: #fff;
}

.flex_03 .box .title {
  font-size: 2.3rem;
  font-weight: bold;
  position: relative;
}

.flex_03 .box .title .en {
  font-size: 1.6rem;
  font-weight: 400;
  display: block;
  border-top: 1px dotted #fff;
  margin-top: 30px;
  padding-top: 30px;
}

.flex_04 {
  justify-content: space-around;
}

.flex_04 .box {
  width: calc((100% - 50px) / 2);
  text-align: center;
}

@media screen and (max-width: 640px) {
  .flex_04 .box {
    width: 100%;
    margin-bottom: 15px;
  }
  .flex_04 .box:last-of-type {
    margin-bottom: 0;
  }
}

.flex_04 .box:first-of-type .tel a {
  color: #199640;
  font-size: 3.2rem;
  font-weight: bold;
}

.flex_04 .box:first-of-type .fax {
  font-size: 3.2rem;
  font-weight: bold;
  color: #199640;
}

.flex_04 .box:last-of-type a {
  background-color: #EF8100;
  font-weight: bold;
  max-width: 230px;
  padding: 20px;
  display: block;
  color: #fff;
  font-size: 1.8rem;
  margin: auto;
}

.flex_05 {
  justify-content: space-between;
}

.flex_05 .box {
  width: calc((100% - 60px) / 3);
}

@media screen and (max-width: 640px) {
  .flex_05 .box {
    width: 100%;
    margin-bottom: 20px;
  }
  .flex_05 .box:last-of-type {
    margin-bottom: 0;
  }
}

.gmap iframe {
  width: 100%;
}

.top-sns .slick-slide {
  margin: 0 15px;
  padding: 30px;
  border: 1px solid #ccc;
}

@media screen and (max-width: 834px) {
  .top-sns .slick-slide {
    padding: 15px;
  }
}

.top-sns .sns_text .sns_date {
  font-family: 'Poppins', sans-serif;
}

.btn a {
  color: #fff;
  position: relative;
  display: block;
  font-weight: bold;
  padding: 24px;
  width: 350px;
  background-color: #FE642E;
  margin: auto;
  line-height: 1em;
}

@media screen and (max-width: 640px) {
  .btn a {
    width: 100%;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  background: #199640;
  border-radius: 0;
  box-shadow: none;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form div.select {
  min-width: 230px !important;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view p.price {
  font-size: 3rem;
}

div.item_view > ul li a {
  margin-bottom: 10px;
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.items {
  justify-content: space-between;
  margin-left: 0 !important;
}

@media screen and (max-width: 640px) {
  .items {
    display: block !important;
  }
}

.items article {
  width: 32% !important;
  margin-left: 0 !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .items article {
    width: 48% !important;
  }
}

@media screen and (max-width: 640px) {
  .items article {
    width: 100% !important;
  }
}

.items article .photo {
  width: 100% !important;
}

.items article .name {
  color: #333333 !important;
}

.items article .price {
  color: #333333 !important;
}

.items article button {
  border-radius: 0 !important;
  background: #ccc !important;
  color: #fff !important;
  border: none !important;
  text-indent: 0 !important;
}

.shopPage div.items > article .thumb {
  z-index: 2;
  position: relative;
}

.shopPage div.items > article .hoverImg {
  position: absolute;
  top: 0;
  left: 0;
}

.shopPage div.items > article a.photo {
  position: relative;
}

.shopPage div.items > article a {
  text-decoration: none;
}

.shopPage div.items > article a.photo {
  width: 100%;
  height: auto;
  background-image: none !important;
}

.shopPage div.items > article a.photo img {
  display: block;
  width: 100% !important;
  height: 264px !important;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

#main #col_side1 {
  display: none;
}

.shopPage #main #col_side1 {
  display: block;
  padding-right: 24px;
}

@media screen and (max-width: 834px) {
  .shopPage #main #col_side1 {
    width: 100% !important;
  }
}

.shopPage #col_main {
  width: calc(100% - 300px) !important;
}

@media screen and (max-width: 834px) {
  .shopPage #col_main {
    width: 100% !important;
  }
}

.view_cart a {
  background-color: #199640;
  display: block;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.view_cart a i {
  margin-right: 10px;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1000px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1000px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1000px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.local_title_01 h2 {
  font-size: clamp(28px, 2vw, 28px);
  line-height: 1.25em;
  font-weight: bold;
  position: relative;
  background-color: #777777;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff;
  padding: 20px 20px 20px 4rem;
}

.local_title_01 h2::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 3px;
  height: 50px;
  background-color: #fff;
  display: block;
}

.local_title_02 h2 {
  font-size: clamp(28px, 2vw, 28px);
  line-height: 1.25em;
  font-weight: bold;
  position: relative;
  background: linear-gradient(to right, #0062b9 1%, #00b1d4 100%);
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff;
  padding: 20px;
}

.sub_title_01 h3 {
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
  background-color: #ccc;
  border: 1px dotted #777777;
  padding: 15px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.sub_title_02 h3 {
  margin-bottom: 30px;
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
  border-bottom: 1px dotted #777777;
  padding-bottom: 10px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.sub_title_03 h3 {
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
  background-color: #D3F4FF;
  border: 1px dotted #0062b9;
  padding: 15px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #0062b9;
}

.sub_title_04 h3 {
  margin-bottom: 30px;
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
  border-bottom: 1px dotted #0062b9;
  padding-bottom: 10px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #0062b9;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .img .imgL {
    max-width: 100% !important;
  }
}

.movie iframe {
  width: 100%;
  height: 100%;
  max-height: 480px;
  min-height: 480px;
}

@media screen and (max-width: 640px) {
  .movie iframe {
    min-height: 315px;
  }
}

.bg_gray {
  border: 1px solid #ccc;
  padding: 20px;
}

.bg_blue {
  border: 1px solid #00b1d4;
  padding: 20px;
}

.textCont h3 {
  margin-bottom: 30px;
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
  border-bottom: 1px dotted #777777;
  padding-bottom: 10px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.local_cont_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media screen and (max-width: 834px) {
  .local_cont_01 {
    display: block;
  }
}

.local_cont_01 .flxL {
  width: 20%;
}

.local_cont_01 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_01 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_01 .flxR {
  width: 75%;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_flex_01 .box {
  border: 1px solid #cccccc;
}

.local_flex_02 {
  flex-direction: row-reverse !important;
  justify-content: space-between;
}

.local_flex_02 .box:first-of-type {
  width: 30%;
}

@media screen and (max-width: 640px) {
  .local_flex_02 .box:first-of-type {
    width: 100%;
  }
}

.local_flex_02 .box:last-of-type {
  width: 68%;
}

@media screen and (max-width: 640px) {
  .local_flex_02 .box:last-of-type {
    width: 100%;
  }
}

.local_flex_02 .box h3 {
  margin-bottom: 30px;
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
  border-bottom: 1px dotted #777777;
  padding-bottom: 10px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.local_flex_reverse_02 {
  justify-content: space-between;
}

.local_flex_reverse_02 .box:first-of-type {
  width: 30%;
}

@media screen and (max-width: 640px) {
  .local_flex_reverse_02 .box:first-of-type {
    width: 100%;
  }
}

.local_flex_reverse_02 .box:last-of-type {
  width: 68%;
}

@media screen and (max-width: 640px) {
  .local_flex_reverse_02 .box:last-of-type {
    width: 100%;
  }
}

.local_flex_reverse_02 .box h3 {
  margin-bottom: 30px;
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
  border-bottom: 1px dotted #777777;
  padding-bottom: 10px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.local_flex_03 .box {
  width: calc((100% - 40px) / 3);
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: center;
}

.local_flex_03 .box:nth-of-type(3n) {
  margin-right: 0;
}

@media screen and (max-width: 640px) {
  .local_flex_03 .box {
    width: 100%;
    margin-right: 0;
  }
  .local_flex_03 .box:last-of-type {
    margin-bottom: 0;
  }
}

.local_flex_03 .box img {
  width: 100% !important;
  height: 250px !important;
  object-fit: cover;
}

@media screen and (max-width: 640px) {
  .local_flex_03 .box img {
    height: auto !important;
  }
}

.local_flex_04 .box {
  width: calc((100% - 30px) / 2);
}

@media screen and (max-width: 640px) {
  .local_flex_04 .box {
    width: 100%;
  }
  .local_flex_04 .box:first-of-type {
    margin-bottom: 15px;
    margin-right: 0 !important;
  }
}

.local_flex_04 .box:first-of-type {
  margin-right: 20px;
}

.local_flex_04 .box img {
  width: 100% !important;
}

.local_flex_05 {
  justify-content: space-between;
}

.local_flex_05 .box {
  width: calc((100% - 40px) / 3);
}

.local_flex_05 .box a {
  background-color: #199640;
  font-weight: bold;
  color: #fff;
  padding: 20px;
  display: block;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .local_flex_05 .box {
    width: 100%;
    margin-bottom: 15px;
  }
  .local_flex_05 .box:last-of-type {
    margin-bottom: 0;
  }
}

.local_flex_06 .box {
  width: calc((100% - 30px) / 2);
  box-shadow: 0px 0px 10px #aaa;
}

.local_flex_06 .box article {
  display: flex;
  flex-direction: column-reverse;
}

.local_flex_06 .box article > div {
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: bold;
  background-color: #ccc;
  border: 1px dotted #777777;
  padding: 15px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media screen and (max-width: 640px) {
  .local_flex_06 .box {
    width: 100%;
  }
  .local_flex_06 .box:first-of-type {
    margin-bottom: 15px;
    margin-right: 0 !important;
  }
}

.local_flex_06 .box:first-of-type {
  margin-right: 20px;
}

.local_flex_06 .box img {
  width: 100% !important;
}

.local_flex_07 .box {
  width: calc((100% - 30px) / 2);
}

.local_flex_07 .box article {
  display: flex;
  flex-direction: column-reverse;
}

.local_flex_07 .box article > div {
  font-size: 1.8rem;
  line-height: 1.2em;
  font-weight: bold;
  background-color: #fff;
  border: 1px dotted #0062b9;
  padding: 15px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #0062b9;
}

@media screen and (max-width: 640px) {
  .local_flex_07 .box {
    width: 100%;
  }
  .local_flex_07 .box:first-of-type {
    margin-bottom: 15px;
    margin-right: 0 !important;
  }
}

.local_flex_07 .box:first-of-type {
  margin-right: 20px;
}

.local_flex_07 .box img {
  width: 100% !important;
}

.dl_01 dt {
  font-weight: bold;
  border: none !important;
}

.dl_01 dd {
  border: none !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
  .dl_01 dl dt, .dl_01 dl dd {
    width: 100% !important;
  }
}

.dl_02 {
  font-size: 1.8rem;
}

.dl_02 dt {
  border: none !important;
  align-items: center !important;
  background-color: #E6E6E6 !important;
  padding: 15px !important;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .dl_02 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_02 dd {
  border: none !important;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dl_02 dd {
    display: block !important;
    width: 100% !important;
  }
}

.dl_02 dl {
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 640px) {
  .dl_02 dl {
    display: block !important;
  }
}

.td_01 .td_back {
  background-color: #FFFFE6;
  font-weight: bold;
}

.td_01 td {
  background-color: #fff;
}

.td_01 td div {
  text-align: center;
}

section div#contents article h1 {
  font-size: clamp(28px, 2vw, 28px);
  line-height: 1.25em;
  font-weight: bold;
  position: relative;
  background-color: #777777;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Noto Serif JP", "Times New Roman", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff;
  padding: 20px 20px 20px 4rem;
  margin-bottom: 30px;
}

section div#contents article h1::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 3px;
  height: 50px;
  background-color: #fff;
  display: block;
}

section div#contents article {
  padding: 0 !important;
}

.blog_title_01 h2 {
  font-size: clamp(28px, 2vw, 28px);
  color: #777777 !important;
  font-weight: bold;
}

.blog_flex_01 {
  justify-content: space-between;
}

.blog_flex_01 .box {
  width: calc((100% - 20px) / 2);
}

@media screen and (max-width: 640px) {
  .blog_flex_01 .box {
    width: 100%;
  }
  .blog_flex_01 .box:first-of-type {
    margin-bottom: 15px;
  }
}

.blog_flex_01 .box img {
  width: 100% !important;
}

.form dt {
  border: none !important;
}

.form dd {
  border: none !important;
}

.form dl {
  border-bottom: 1px solid #ccc !important;
  padding: 15px 0;
}

.policy_cont h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #199640;
  padding-bottom: 15px;
  font-weight: bold;
  margin: 50px 0 20px 0;
}

.mail {
  display: none;
}

.confirm {
  margin-top: 140px !important;
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.message {
  margin-top: 280px !important;
}
